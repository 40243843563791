import React, { useState, useEffect } from 'react'
import dynamic from '@next-tools/dynamic'
import loadable from '@loadable/component'

import './category-carousel.scss'
import SliderArrowButton from '../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const ChassisCarousel = () => {
  const images = [
    '/images/mono/chassis/MONO_Slider_Chassis_1.jpeg',
    '/images/mono/chassis/MONO_Slider_Chassis_2.jpeg',
    '/images/mono/chassis/MONO_Slider_Chassis_3.jpeg',
    '/images/mono/chassis/MONO_Slider_Chassis_4.jpeg',
    '/images/mono/chassis/MONO_Slider_Chassis_5.jpeg',
    '/images/mono/chassis/MONO_Slider_Chassis_6.jpg',
    '/images/mono/chassis/MONO_Slider_Chassis_7.jpg',
  ]
  return (
    <Carousel
      className="chassis-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_1.jpeg"
        />
      </div>
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_2.jpeg"
        />
      </div>
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_3.jpeg"
        />
      </div>
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_4.jpeg"
        />
      </div>
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_5.jpeg"
        />
      </div>
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_6.jpg"
        />
      </div>
      <div>
        <img
          alt="Chassis carousel"
          src="/mono/chassis/MONO_Slider_Chassis_7.jpg"
        />
      </div>
    </Carousel>
  )
}

export default ChassisCarousel
