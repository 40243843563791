import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header/header'
import ProductBox from '../components/product/product-box'
import Category from '../components/category/category'
import Contact from '../components/contact/contact'
import Footer from '../components/layout/footer'

import ExteriorCarousel from '../components/carousel/exterior-carousel'
import InteriorCarousel from '../components/carousel/interior-carousel'
import ChassisCarousel from '../components/carousel/chassis-carousel'

import SpecsTable from '../components/table/specs-table'
import Container from '../components/layout/container'
import HeaderCarousel from '../components/carousel/header-carousel'
import CookieBox from '../components/cookie/cookie-box'
import '../components/pages/mono.scss'
import { useTranslation } from 'react-i18next'

const MonoPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | MONO</title>
        <body className="product-page" />
      </Helmet>
      <div style={{ background: '#151510' }} className="mono-page">
        <Header />
        <CookieBox />
        <HeaderCarousel />
        <div className="categories-container">
          <Category
            id="exterior"
            name={t('monoPage.exterior.title')}
            desc={
              <>
                <p>{t('monoPage.exterior.body.p1')}</p>
                <p>{t('monoPage.exterior.body.p2')}</p>
                <p>{t('monoPage.exterior.body.p3')}</p>
                <p>{t('monoPage.exterior.body.p4')}</p>
              </>
            }
          />
          <ExteriorCarousel />

          <Category
            name={t('monoPage.interior.title')}
            desc={
              <>
                <p>{t('monoPage.interior.body.p1')}</p>
                <p>{t('monoPage.interior.body.p2')}</p>
                <p>{t('monoPage.interior.body.p3')}</p>
                <p>{t('monoPage.interior.body.p4')}</p>
              </>
            }
          />
          <InteriorCarousel />

          <Category
            name={t('monoPage.chassis.title')}
            desc={
              <>
                <p>{t('monoPage.chassis.body.p1')}</p>
                <p>{t('monoPage.chassis.body.p2')}</p>
                <p>{t('monoPage.chassis.body.p3')}</p>
              </>
            }
          />
          <ChassisCarousel />
        </div>
        <Container>
          <SpecsTable />
        </Container>

        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default MonoPage
