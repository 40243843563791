import React, { useState, useEffect } from 'react'
import dynamic from '@next-tools/dynamic'
import loadable from '@loadable/component'

import './category-carousel.scss'
import SliderArrowButton from '../controls/slider-arrow-button'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { StaticImage } from 'gatsby-plugin-image'

const InteriorCarousel = () => {
  return (
    <Carousel
      className="interior-carousel category-carousel"
      autoPlay={true}
      infiniteLoop={true}
      interval={4000}
      transitionTime={1000}
      showThumbs={false}
      showIndicators={false}
      showArrows={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <SliderArrowButton onClick={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <SliderArrowButton right onClick={onClickHandler} />
      )}
    >
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_1.jpg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_2.jpg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_3.jpg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_4.JPG"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_5.jpeg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_6.jpeg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_7.jpeg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_8.jpeg"
        />
      </div>
      <div>
        <img
          alt="Mono interior carousel"
          src="/mono/interior/MONO_Slider_Interior_9.jpeg"
        />
      </div>
    </Carousel>
  )
}

export default InteriorCarousel
